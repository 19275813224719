import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <h1>wut</h1>
    <img src="https://http.cat/404.jpg" alt="404 cat doesn't want to be found" />
    <br />
    <br />
    <br />
  </React.Fragment>
);

export default NotFoundPage;
